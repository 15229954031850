import React from 'react';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@zep/ui/experimental';
import { cn, getResizedImage } from '@zep/utils';

import { GNBMenu } from '../../../types';

export const GlobalNavigationBarMenu = (props: GNBMenuProps) => {
  const { headerContent } = props;

  return (
    <NavigationMenu>
      <NavigationMenuList>
        {headerContent.map(menu => (
          <NavigationMenuItem key={menu.title}>
            {menu.menus && menu.menus.length > 0 ? (
              <>
                <NavigationMenuTrigger
                  className={cn(
                    'text-[14px] font-[600] text-gray-700',
                    'data-[active]:bg-[#27262E14] data-[state=open]:bg-[#27262E14]',
                    'hover:bg-[#27262E14] hover:text-[#27262E]',
                    'focus:bg-[#27262E14] focus:text-[#27262E]',
                  )}>
                  {menu.title}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul
                    className={cn(
                      'grid grid-cols-2 w-[428px] gap-x-[12px] gap-y-[6px] p-[18px] rounded-[8px]',
                      // 소개 탭일 때 width 조정
                      {
                        'w-[618px]': menu.isWide,
                      },
                    )}>
                    {menu.menus
                      .filter(subMenu => !subMenu.hidden)
                      .map(subMenu => (
                        <NavigationSubMenuItem
                          key={subMenu.name}
                          title={subMenu.name}
                          iconUrl={subMenu.icon}
                          href={subMenu.url}
                          description={subMenu?.description}
                        />
                      ))}
                  </ul>
                </NavigationMenuContent>
              </>
            ) : (
              <NavigationMenuLink
                className={cn(
                  navigationMenuTriggerStyle(),
                  'text-[14px] font-[600] text-gray-700',
                  'hover:bg-[#27262E14] hover:text-[#27262E]',
                  'focus:bg-[#27262E14] focus:text-[#27262E]',
                )}
                href={menu.path}>
                {menu.title}
              </NavigationMenuLink>
            )}
          </NavigationMenuItem>
        ))}
        <NavigationMenuIndicator />
      </NavigationMenuList>
    </NavigationMenu>
  );
};

const NavigationSubMenuItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'> & {
    title: string;
    iconUrl?: string;
    description?: string;
  }
>(({ className, title, iconUrl, description, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            'block select-none rounded-md px-[12px] pt-[12px] pb-[10px] leading-none no-underline outline-none transition-colors',
            'hover:bg-gray-50',
            'focus:bg-gray-50',
            className,
          )}
          {...props}>
          <div className="flex items-start gap-[10px]">
            {iconUrl && (
              <img
                width={24}
                height={24}
                src={getResizedImage(iconUrl, 24)}
                alt="icon"
              />
            )}
            <div className="flex flex-col justify-start gap-[4px]">
              <div className="text-body-1 pt-[2px] font-[700] text-gray-700">
                {title}
              </div>
              {description && (
                <p className="text-body-1 line-clamp-2 font-[500] text-[#27262EA3]">
                  {description}
                </p>
              )}
            </div>
          </div>
        </a>
      </NavigationMenuLink>
    </li>
  );
});
NavigationSubMenuItem.displayName = 'NavigationSubMenuItem';

type GNBMenuProps = {
  headerContent: GNBMenu[];
};

'use client';

import * as React from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Circle } from 'lucide-react';

import { cn } from '../../utils/tailwind';

type RadioGroupComponent = typeof RadioGroupPrimitive.Root & {
  Item: typeof RadioGroupItem;
};

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root className={className} {...props} ref={ref} />
  );
}) as RadioGroupComponent;
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      {...props}
      className={cn(
        'aspect-square min-h-[20px] min-w-[20px] rounded-full border-solid border-2 !border-gray-300 text-primary ring-offset-background bg-white',
        'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:border-gray-200 disabled:text-primary-disabled',
        className,
      )}>
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="size-[13px] fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

RadioGroup.Item = RadioGroupItem;

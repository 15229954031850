import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { CaretDownIcon, CheckSmallIcon } from '@zep/icons';

import { cn } from '../../utils/tailwind';

type OverrideChildren<T> = Omit<T, 'children'> & {
  children: ReactNode;
};

const Select = SelectPrimitive.Root;

const SelectGroup = forwardRef<
  ElementRef<typeof SelectPrimitive.Group>,
  OverrideChildren<ComponentPropsWithoutRef<typeof SelectPrimitive.Group>>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Group
    ref={ref}
    className={cn('space-y-1', className)}
    {...props}>
    {children}
  </SelectPrimitive.Group>
));
SelectGroup.displayName = SelectPrimitive.Group.displayName;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef<
  ElementRef<typeof SelectPrimitive.Trigger>,
  OverrideChildren<ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      [
        'inline-flex',
        'h-10',
        'items-center',
        'justify-between',
        'rounded-lg',
        'border',
        'border-input',
        'bg-background',
        'px-3',
        'text-subtitle',
        'font-medium',
        'focus:outline-none',
        'whitespace-nowrap',
        'disabled:cursor-not-allowed',
        'disabled:opacity-50',
        'disabled:bg-gray-100',
        'bg-white',
        // 'hover:border-primary',
        'data-[state=open]:border-primary',
      ],
      className,
    )}
    {...props}>
    {children}
    <SelectPrimitive.Icon asChild>
      <CaretDownIcon className="size-4 text-gray-700" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = forwardRef<
  ElementRef<typeof SelectPrimitive.Content>,
  OverrideChildren<ComponentPropsWithoutRef<typeof SelectPrimitive.Content>>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        [
          'relative',
          'z-50',
          'min-w-[8rem]',
          'overflow-hidden',
          'rounded-md',
          'border',
          'bg-popover',
          'text-popover-foreground',
          'shadow-md',
          'data-[state=open]:animate-in',
          'data-[state=closed]:animate-out',
          'data-[state=closed]:fade-out-0',
          'data-[state=open]:fade-in-0',
          'data-[side=bottom]:slide-in-from-top-2',
          'data-[side=left]:slide-in-from-right-2',
          'data-[side=right]:slide-in-from-left-2',
          'data-[side=top]:slide-in-from-bottom-2',
          'bg-white',
        ],
        position === 'popper' && [
          'data-[side=top]:-translate-y-0',
          'data-[side=bottom]:translate-y-0',
          'data-[side=left]:-translate-x-1',
          'data-[side=right]:translate-x-1',
        ],
        className,
      )}
      position={position}
      {...props}>
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          'px-2',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}>
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = forwardRef<
  ElementRef<typeof SelectPrimitive.Label>,
  OverrideChildren<ComponentPropsWithoutRef<typeof SelectPrimitive.Label>>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = forwardRef<
  ElementRef<typeof SelectPrimitive.Item>,
  OverrideChildren<ComponentPropsWithoutRef<typeof SelectPrimitive.Item>>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      [
        'relative',
        'flex',
        'cursor-default',
        'select-none',
        'items-center',
        'rounded',
        'py-1',
        'px-2',
        'h-9',
      ],
      [
        'text-body-2',
        'font-medium',
        'outline-none',
        'text-gray-700',
        'data-[disabled]:pointer-events-none',
        'hover:bg-gray-50',
        'data-[highlighted]:bg-gray-50',
        'data-[state=checked]:bg-primaryBg',
        'data-[highlighted]:data-[state=checked]:bg-primaryBg',
        'data-[state=checked]:text-primary',
        'data-[state=checked]:font-[600]',
        // 'focus:bg-accent',
        // 'focus:text-accent-foreground',
      ],
      className,
    )}
    {...props}>
    <SelectPrimitive.ItemText asChild>
      <span className="flex-1">{children}</span>
    </SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <CheckSmallIcon width={24} height={24} className="text-primary" />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = forwardRef<
  ElementRef<typeof SelectPrimitive.Separator>,
  OverrideChildren<ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-muted', className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
};

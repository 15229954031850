import * as React from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

import { cn } from '../../utils/tailwind';

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Root
      className={cn(
        'inline-flex border rounded-lg border-gray-200 overflow-hidden',
        className,
      )}
      {...props}
      ref={ref}
    />
  );
});
ToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        [
          'flex-1',
          'px-2',
          'py-2.5',
          'text-body-1',
          'font-medium',
          'border-r',
          'text-gray-600',
          'border-gray-200',
          'whitespace-nowrap',
          'last-of-type:border-none',
          'data-[state=on]:bg-primaryBg',
          'data-[state=on]:text-primary',
          'hover:bg-gray-50',
          'hover:data-[state=on]:bg-primaryBg-hover',
        ],
        className,
      )}
      {...props}>
      {children}
    </ToggleGroupPrimitive.Item>
  );
});
ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { ToggleGroup, ToggleGroupItem };

'use client';
import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import { SearchInput } from './SearchInput';

type InputValue = InputHTMLAttributes<HTMLInputElement>['value'];

export type DebouncedSearchInputProps = ComponentProps<typeof SearchInput> & {
  value: InputValue;
  onChange: (value: InputValue) => void;
  onClear?: () => void;
  debounce?: number;
};

export const DebouncedSearchInput = forwardRef<
  HTMLInputElement,
  DebouncedSearchInputProps
>(
  (
    { value: initialValue, debounce = 500, onChange, onClear, ...props },
    ref,
  ) => {
    const [value, setValue] = useState<InputValue>(initialValue);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    return (
      <SearchInput
        value={value}
        onChange={handleChangeInput}
        onClear={onClear}
        ref={ref}
        {...props}
      />
    );
  },
);

DebouncedSearchInput.displayName = 'DebouncedSearchInput';

import * as React from 'react';
import { SVGProps } from 'react';
export const XSmallCircleFillIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2ZM9.207 7.793a1 1 0 0 0-1.414 1.414L10.586 12l-2.793 2.793a1 1 0 1 0 1.414 1.414L12 13.414l2.793 2.793a1 1 0 0 0 1.414-1.414L13.414 12l2.793-2.793a1 1 0 0 0-1.414-1.414L12 10.586 9.207 7.793Z"
      fill={props.fill || `currentColor`}
    />
  </svg>
);

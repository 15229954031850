import { zepEventTracker } from './zepEventTracker';

// FIXME: setUserProperties 외에도 useSpaceMapAmplitudePlugin를 이용해서
// 전역적으로 추가 정보(eventProperties)를 넣어주는 코드가 있습니다.
// zepAnalytics와 코드 레벨에서 묶어서 해당 로직을 놓치지 않도록 개선해야 합니다.

export const zepAnalytics: ZepAnalytics = {
  track: async (eventName, eventProperties) => {
    zepEventTracker.track(eventName, eventProperties);
  },
};

type ZepAnalytics = {
  track: (
    eventName: string,
    eventProperties?: Record<string, unknown>,
  ) => ReturnType<typeof zepEventTracker.track>;
};

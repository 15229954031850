'use client';

import * as React from 'react';
import { ReactNode } from 'react';

import {
  CaretDownIcon,
  CheckSmallIcon,
  XSmallCircleColorIcon,
} from '@zep/icons';

import { Colors } from '../../../dist/index';
import { CircularProgress, IconContainer } from '../../';
import { Button } from '../Button';
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading,
} from '../Command';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';

export function AsyncCombobox(props: ComboboxProps) {
  const [open, setOpen] = React.useState(false);

  const rightSlot =
    props.select.value && !open ? (
      <IconContainer
        onClick={e => {
          e.preventDefault();
          props.select.onChange('');
        }}>
        <XSmallCircleColorIcon width={20} height={20} />
      </IconContainer>
    ) : !open ? (
      <CaretDownIcon width={20} height={20} />
    ) : null;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          style={{ width: props.width }}
          role="combobox"
          intent={'sub'}
          variant={'transparent'}
          aria-expanded={open}
          className="hover:border-primary-hover justify-between border border-gray-200 hover:bg-white">
          <div className={'flex w-[90%] justify-start'}>
            {props.select.value
              ? props.options.find(
                  option => option.value === props.select.value,
                )?.label
              : props.select.placeholder ?? ''}
          </div>
          <div className={'w-10%'}>{rightSlot}</div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{ zIndex: 9999, maxHeight: 220, width: props.width }}
        className="w-full p-0">
        <Command
          className={'w-full'}
          shouldFilter={false}
          value={props.select.value}>
          <CommandInput
            placeholder={props.input.placeholder ?? ''}
            value={props.input.value}
            onValueChange={props.input.onChange}
          />
          <CommandList className={'px-[8px] py-[4px]'}>
            {props.isLoading ? (
              <CommandLoading className={'flex'}>
                <div
                  style={{ width: props.width }}
                  className={
                    'w-full] flex h-[36px] items-center justify-center gap-[8px]'
                  }>
                  <CircularProgress size={16} color={Colors.Gray200} />
                  {props.loadingMessage || '로딩 중...'}
                </div>
              </CommandLoading>
            ) : (
              <CommandEmpty
                className={
                  'w-full] flex h-[36px] items-center justify-center gap-[8px]'
                }>
                {props.emptyMessage || '일치하는 데이터가 없습니다'}
              </CommandEmpty>
            )}
            {props.isLoading
              ? []
              : props.options.map(option => (
                  <CommandItem
                    className={`${
                      option.value === props.select.value ? 'bg-primaryBg' : ''
                    } flex justify-between rounded-[4px] px-[10px] hover:bg-gray-50`}
                    key={option.value}
                    value={option.value}
                    onSelect={currentValue => {
                      // 대소문자를 소문자로 알아서 바꾼다.! 대소문자를 조심하세요
                      props.select.onChange(
                        props.options.find(
                          op => op.value.toLowerCase() === currentValue,
                        )?.value || currentValue,
                      );
                      setOpen(false);
                    }}>
                    {option.label}
                    {option.value === props.select.value ? (
                      <CheckSmallIcon
                        width={24}
                        height={24}
                        color={Colors.PrimaryDefault}
                      />
                    ) : null}
                  </CommandItem>
                ))}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

export type ComboboxProps = {
  input: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
  };
  select: {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
  };
  isLoading: boolean;
  loadingMessage?: string;
  emptyMessage?: string;
  options: { value: string; label: ReactNode }[];
  width: string;
};

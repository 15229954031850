import { v4 as uuidv4 } from 'uuid';

import { Logger, logstashLogger } from '@zep/logger';
import { getDeviceInformation } from '@zep/utils';

import { EventProperties, ExternalEventTrackingClient } from './types';
import { zettaClient } from './zettaClient';

const DEFAULT_CONTEXT_PROPERTIES = {
  zep_deployment_env: process.env.NEXT_PUBLIC_STAGE,
  zep_service_name: 'zep-core',
};

class ZepEventTracker {
  private logger: Logger;
  private externalClient: ExternalEventTrackingClient;
  private userId: string | undefined;
  private sessionId: string;
  private context: {
    // Amplitude Plugin과 동일하게 동작하도록, track할 때마다 넣어주는 추가 정보입니다. Ref: useSpaceMapAmplitudePlugin
    eventProperties: EventProperties;
  };
  metadata: Record<string, unknown>;

  constructor() {
    this.logger = logstashLogger;
    this.externalClient = zettaClient;
    this.sessionId = this.getSessionId();
    this.context = {
      eventProperties: DEFAULT_CONTEXT_PROPERTIES,
    };
    this.initializeMetadata();
  }

  async initializeMetadata() {
    this.metadata = {};

    if (typeof window !== 'undefined') {
      const { isApp, browserName, osName, isMobile } = getDeviceInformation();
      this.metadata.isApp = isApp;
      this.metadata.browser = browserName;
      this.metadata.os = osName;
      this.metadata.isMobile = isMobile;
      this.metadata.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  public setUserId(id: string | undefined) {
    this.userId = id;

    if (id == null) {
      this.externalClient.resetIdentity();
      return;
    }

    this.externalClient.identify(id);
  }

  public setContext(eventProperties: EventProperties) {
    this.context.eventProperties = {
      ...DEFAULT_CONTEXT_PROPERTIES,
      ...eventProperties,
    };
    this.externalClient.setUserProperties(this.context.eventProperties);
  }

  public addContext(eventProperties: EventProperties) {
    const newContext = {
      ...this.context.eventProperties,
      ...eventProperties,
    };
    this.context.eventProperties = newContext;
    this.externalClient.setUserProperties(newContext);
  }

  public track(eventName: string, eventProperties?: EventProperties) {
    const mergedProperties = {
      ...this.context.eventProperties,
      ...eventProperties,
    };

    this.logger.info(eventName, {
      additionalData: {
        type: 'ZEP_CLIENT_EVENT',
        event: {
          name: eventName,
          properties: mergedProperties,
          metadata: this.metadata,
          userId: this.userId,
          sessionId: this.sessionId,
        },
      },
    });

    this.externalClient.track(eventName, mergedProperties);
  }

  private getSessionId(): string {
    if (typeof window === 'undefined') {
      return uuidv4();
    }

    const storageKey = '@p@@ze__u_id';
    let userId = window.localStorage.getItem(storageKey);
    if (userId == null) {
      userId = uuidv4();
      window.localStorage.setItem(storageKey, userId);
    }
    return userId;
  }
}

export const zepEventTracker = new ZepEventTracker();

import { PropsWithChildren, useEffect, useState } from 'react';
import { useBooleanState } from '@toss/react';
import { useRouter } from 'next/router';

import { GoogleAnalytics, NaverAnalytics } from '@zep/analytics';
import { DialogProvider, Toast } from '@zep/dialog';
import { AcceptCookieWrapper } from '@zep/web-components';

import { useChannelTalkBoot } from '../../hooks/useChannelTalkBoot';
import { initSentry } from '../../utils';

const RootLayout = (props: RootLayoutProps) => {
  const { children } = props;
  const router = useRouter();
  const [isShowCookiePanel, setShowCookiePanel] = useState(false);
  const [isAllowedAnalytics, allowAnalytics] = useBooleanState(true);

  const enabledCookieAccept = !router.pathname.includes('/web3');
  // 채널톡 세팅
  useChannelTalkBoot(!isShowCookiePanel);

  useEffect(() => {
    initSentry();
  }, []);

  return (
    <DialogProvider>
      <AcceptCookieWrapper
        enabledCookiePanel={enabledCookieAccept}
        onAcceptedCookie={allowAnalytics}
        updateVisibilityCookiePanel={setShowCookiePanel}>
        {children}
        <div id="tooltip" />
        <Toast />
        {isAllowedAnalytics && <GoogleAnalytics />}
        {isAllowedAnalytics && <NaverAnalytics />}
      </AcceptCookieWrapper>
    </DialogProvider>
  );
};

export default RootLayout;

type RootLayoutProps = PropsWithChildren;

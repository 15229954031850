import * as React from 'react';
import { SVGProps } from 'react';
export const MinusSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x={7}
      y={11}
      width={10}
      height={2}
      rx={1}
      fill={props.fill || `currentColor`}
    />
  </svg>
);

import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  SVGProps,
} from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { MinusSmallIcon } from '@zep/icons';

import { cn } from '../../utils/tailwind';

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={10}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.664.253a1 1 0 0 1 .083 1.411l-5.333 6a1 1 0 0 1-1.495 0l-2.666-3a1 1 0 1 1 1.494-1.328l1.92 2.159L8.253.335A1 1 0 0 1 9.664.254Z"
      fill="currentColor"
    />
  </svg>
);

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => {
  const isIndeterminate = props.checked === 'indeterminate';

  return (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        [
          'peer',
          'inline-flex',
          'items-center',
          'justify-center',
          'h-5',
          'w-5',
          'border-2',
          'bg-white',
          'rounded-[3px]',
          'align-middle',
          'border-gray-300',
          'text-gray-300',

          'ring-offset-background',
          'focus-visible:outline-none',
          'focus-visible:ring-2',
          'focus-visible:ring-ring',
          'focus-visible:ring-offset-2',
          'disabled:cursor-not-allowed',

          'data-[state=checked]:border-primary',
          'data-[state=checked]:bg-primary',
          'data-[state=checked]:text-white',

          'data-[state=indeterminate]:border-primary',
          'data-[state=indeterminate]:bg-primary',
          'data-[state=indeterminate]:text-white',

          'data-[disabled]:border-gray-200',
          'data-[disabled]:bg-gray-50',
          'data-[disabled]:text-gray-200',

          'data-[state=checked][disabled]:border-primary-disabled',
          'data-[state=checked][disabled]:bg-primary-disabled',
          'data-[state=checked][disabled]:text-gray-50',

          'data-[state=indeterminate][disabled]:border-primary-disabled',
          'data-[state=indeterminate][disabled]:bg-primary-disabled',
          'data-[state=indeterminate][disabled]:text-gray-50',
        ],
        className,
      )}
      {...props}>
      {isIndeterminate ? <MinusSmallIcon /> : <CheckIcon />}
    </CheckboxPrimitive.Root>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

'use client';
import {
  ChangeEvent,
  ComponentProps,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import { MagnifingglassIcon } from '@zep/icons';

import { Input } from '../../_experimental';

type InputValue = InputHTMLAttributes<HTMLInputElement>['value'];

export type DebouncedInputProps = ComponentProps<typeof Input> & {
  value: InputValue;
  onChange: (value: InputValue) => void;
  debounce?: number;
};

export const DebouncedInput = forwardRef<HTMLInputElement, DebouncedInputProps>(
  ({ value: initialValue, debounce = 500, onChange, ...props }, ref) => {
    const [value, setValue] = useState<InputValue>(initialValue);

    useEffect(() => {
      const timeout = setTimeout(() => {
        onChange(value);
      }, debounce);

      return () => clearTimeout(timeout);
    }, [debounce, onChange, value]);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };
    return (
      <Input
        size="md"
        leftSlot={<MagnifingglassIcon width={20} height={20} />}
        value={value}
        onChange={handleChangeInput}
        ref={ref}
        {...props}
      />
    );
  },
);

DebouncedInput.displayName = 'DebouncedInput';

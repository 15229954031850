'use client';

import { Dispatch, ReactNode, SetStateAction } from 'react';
import * as React from 'react';
import { DateRange, DayPickerRangeProps } from 'react-day-picker';

import { cn } from '../../utils/tailwind';
import { Calendar, Popover, PopoverContent, PopoverTrigger } from '../';

export type DatePickerWithRangeProps = {
  children?: ReactNode;
  calendarProps?: Partial<Omit<DayPickerRangeProps, 'mode'>>;
  className?: string;
  dateRange: DateRange | undefined;
  setDateRange: Dispatch<SetStateAction<DateRange | undefined>>;
  footer?: ReactNode;
  onPopOverOpenChange?: (open: boolean) => void;
};

export function DateRangePicker({
  children,
  className,
  dateRange,
  setDateRange,
  calendarProps,
  footer,
  onPopOverOpenChange,
}: DatePickerWithRangeProps) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover onOpenChange={onPopOverOpenChange}>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverContent className="w-auto overflow-hidden p-0" align="end">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            numberOfMonths={2}
            selected={dateRange}
            onSelect={setDateRange}
            {...calendarProps}
          />
          {footer}
        </PopoverContent>
      </Popover>
    </div>
  );
}

import posthog from 'posthog-js';

import { EventProperties, ExternalEventTrackingClient } from './types';

class ZettaClient implements ExternalEventTrackingClient {
  constructor() {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY ?? '', {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST ?? '',
      // 아래 옵션은 Zetta 운영 측 요청에 의해 설정한 값입니다. 지원하지 않는 기능이라고 합니다.
      capture_pageview: false,
      capture_pageleave: false,
      autocapture: false,
      disable_session_recording: true,
      debug: false,
    });
  }

  public identify(userId: string, eventProperties?: EventProperties) {
    posthog.identify(userId, eventProperties);
  }

  public resetIdentity() {
    posthog.reset();
  }

  public setUserProperties(eventProperties: EventProperties) {
    posthog.setPersonProperties(eventProperties);
  }

  public track(eventName: string, eventProperties?: EventProperties) {
    posthog.capture(eventName, eventProperties);
  }
}

export const zettaClient = new ZettaClient();

'use client';

import * as React from 'react';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { DayPickerSingleProps } from 'react-day-picker';

import { cn } from '@zep/utils';

import { Calendar } from '../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';

export type DatePickerProps = {
  children?: ReactNode;
  calendarProps?: Partial<Omit<DayPickerSingleProps, 'mode'>>;
  className?: string;
  triggerClassName?: string;
  contentClassName?: string;
  selected: Date | undefined;
  onSelect: Dispatch<SetStateAction<Date | undefined>>;
  footer?: ReactNode;
  onPopOverOpenChange?: (open: boolean) => void;
  popoverAlign?: 'end' | 'start' | 'center';
  isPopoverOpen?: boolean;
};

export function DatePicker(props: DatePickerProps) {
  const {
    selected,
    onSelect,
    children,
    className,
    triggerClassName,
    contentClassName,
    calendarProps,
    onPopOverOpenChange,
    footer,
    popoverAlign,
    isPopoverOpen,
  } = props;

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover onOpenChange={onPopOverOpenChange} open={isPopoverOpen}>
        <PopoverTrigger
          className={cn(
            'data-[state=open]:border-primary',
            'data-[state=open]:border',
            triggerClassName,
          )}
          asChild>
          {children}
        </PopoverTrigger>
        <PopoverContent
          className={cn('w-auto p-0', contentClassName)}
          align={popoverAlign}>
          <Calendar
            initialFocus
            mode="single"
            defaultMonth={selected}
            selected={selected}
            onSelect={onSelect}
            {...calendarProps}
          />
          {footer}
        </PopoverContent>
      </Popover>
    </div>
  );
}

import { ComponentProps } from 'react';

import { MagnifingglassIcon, XSmallCircleFillIcon } from '@zep/icons';

import { Input } from './Input';

interface SearchInputProps extends ComponentProps<typeof Input> {
  onClear?: () => void;
}
export const SearchInput = ({ onClear, value, ...rest }: SearchInputProps) => {
  return (
    <div className="relative inline-flex align-middle">
      <Input
        leftSlot={<MagnifingglassIcon width={20} height={20} />}
        value={value}
        {...rest}
      />
      {String(value).length > 0 && (
        <button
          type="button"
          className="absolute right-0 flex h-full items-center px-2"
          onClick={onClear}>
          <XSmallCircleFillIcon
            width={20}
            height={20}
            className="rounded-full text-gray-400"
          />
        </button>
      )}
    </div>
  );
};

import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { User } from '@zep/types';

import { buildFormData } from '../../utils';
import { UseZepApiMutationOptions } from '../types';
import { useZepApiClient } from '../ZepApiProvider';

import { QUERY_KEY_ME } from './query';
import { PlayerResponse } from './types';

/**
 * 내 정보 조회 API Hook
 * @version V2
 * @returns Mutation States
 */
/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useGetMe(options?: UseZepApiMutationOptions<User>) {
  const client = useZepApiClient();
  return useMutation(
    async () => {
      const { data } = await client.get(`/v2/me`);
      return data.player;
    },
    {
      ...options,
    },
  );
}

/**
 * 내 정보 수정 Mutation Hook
 * @version V1
 * @returns Mutation States
 */
/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useUpdateMe(
  options?: UseZepApiMutationOptions<void, Partial<PlayerResponse>>,
) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    player => {
      return client.postForm('/me', buildFormData(player));
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries(QUERY_KEY_ME);
      },
    },
  );
}

/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useLogout(options?: UseZepApiMutationOptions) {
  const client = useZepApiClient();
  const queryClient = useQueryClient();
  return useMutation(
    async () => {
      await client.postForm('/v2/me/logout');
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.removeQueries(QUERY_KEY_ME);
      },
    },
  );
}

/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useWithdraw(
  options?: UseMutationOptions<{
    status: number;
    statusKey: string;
    data: null;
  }>,
) {
  const client = useZepApiClient();
  return useMutation(async () => {
    const { data } = await client.post('/v2/me/delete');
    return data;
  }, options);
}

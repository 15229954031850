import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from '@tanstack/react-query';

import { User } from '@zep/types';

import { useZepApiClient } from '../ZepApiProvider';

export const QUERY_KEY_ME = ['QUERY_KEY_ME'];
type UseMeQuery = typeof useQuery<User, unknown, User | null>;
type UseMeQueryOptions = Parameters<UseMeQuery>[2];

/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useMe(options?: UseMeQueryOptions) {
  const client = useZepApiClient();
  return useQuery<User, unknown, User | null>(
    QUERY_KEY_ME,
    async () => {
      const { data } = await client.get('/v2/me');
      return data.player as User;
    },
    options,
  );
}

const QUERY_KEY = {
  VALIDATE_WITHDRAW: 'validate-withdraw',
} as const;

/**
 * @deprecated libs/service/apis/src/openapi의 최신 스키마로 업데이트 해주세요.
 */
export function useValidateWithdraw(
  options?: UseMutationOptions<{
    status: number;
    statusKey: string;
    data: null;
  }>,
) {
  const client = useZepApiClient();
  return useMutation(
    [QUERY_KEY.VALIDATE_WITHDRAW],
    async () => {
      const { data } = await client.get('/v2/me/validate-deletion');
      return data;
    },
    options,
  );
}

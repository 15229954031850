import { ReactNode } from 'react';
import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';

import { cn } from '../../utils/tailwind';

const buttonVariants = cva(
  [
    'focus-visible:ring-primary-bg-hover',
    'inline-flex',
    'items-center',
    'justify-center',
    '!leading-none',
    'shadow-[inset_0_0_0_1px_rgba(0,0,0,0.03)]',
    'outline-none',
    'focus-visible:ring-2',
  ],
  {
    variants: {
      intent: {
        primary:
          'bg-primary hover:bg-primary-hover disabled:bg-primary-disabled text-white shadow-none disabled:text-white/70',
        secondary:
          'bg-primary bg-primaryBg hover:bg-primaryBg-hover text-primary disabled:text-primary/40 disabled:bg-gray-50',
        sub: 'bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:bg-gray-50 disabled:text-gray-600/40 hover:disabled:bg-gray-50',
        alert:
          'bg-redBg text-red hover:bg-redBg-hover disabled:bg-redBg/[0.4] disabled:text-red/40',
      },
      size: {
        lg: 'text-subtitle h-12 rounded-lg px-4 font-semibold',
        md: 'text-body-1 h-10 rounded-lg px-3 font-semibold',
        sm: 'text-body-1 h-9 rounded-md px-3 font-semibold',
        xs: 'text-caption-1 h-[30px] rounded-[4px] px-[10px] font-semibold',
        xxs: 'text-caption-1 h-[26px] rounded-[4px] px-2 font-semibold',
      },
      variant: {
        contained: '',
        transparent: 'bg-transparent shadow-none disabled:bg-transparent',
        rounded: 'rounded-full',
      },
    },
    compoundVariants: [
      {
        intent: 'primary',
        variant: 'transparent',
        className:
          'text-primary hover:text-primary-hover disabled:text-primary-disabled hover:bg-gray-50',
      },
      {
        intent: 'sub',
        variant: 'transparent',
        className: 'hover:bg-gray-50 hover:disabled:bg-transparent',
      },
      {
        intent: 'alert',
        variant: 'transparent',
        className: 'hover:bg-[#fff8fb]',
      },
    ],
    defaultVariants: {
      intent: 'primary',
      size: 'md',
      variant: 'contained',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  children: ReactNode;
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, intent, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ intent, variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };

import { useMemo, useState } from 'react';
import { Else, If, Then, Unless } from 'react-if';
import { useEffectOnce } from 'react-use';
import { Menu } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import cns from 'classnames';
import { deleteCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';

import { useAuth } from '@zep/auth';
import { SSRSafeSuspense } from '@zep/common/react-boundary';
import { MenuIcon } from '@zep/icons';
import { useViewportDevice } from '@zep/react';
import { Language } from '@zep/types';

import { NavigationMenu } from '../../../types';
import { isDevelopment } from '../../../utils';

import { GlobalNavigationBarMenu } from './GlobalNavigationBarMenu';
import S from './Header.module.scss';
import { HeaderCTAButton, Logos } from './HeaderCommons';
import MobileGlobalNavigationBarMenu from './MobileGlobalNavigationBarMenu';

export const Header = ({ isHideMenu = false }: { isHideMenu?: boolean }) => {
  const router = useRouter();
  const { data: headerContent } = useQuery(
    ['header_content', router.locale],
    () => import(`./header.${router.locale}.json`).then(mod => mod.default),
  );
  const filteredHeaderContent = useMemo(() => {
    return headerContent?.filter((menu: NavigationMenu) => !menu.hidden) ?? [];
  }, [headerContent]);

  const [isShowMobileNav, setIsShowMobileNav] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const { isPortableDevice } = useViewportDevice();

  const notAllowPaths = ['/store/*'];

  /**
   * 스페이스 목록페이지와 스토어 관련 페이지일 경우 헤더 그림자를 숨깁니다.
   */
  const isNotAllowPaths = (inputPaths: string, paths: string[]) => {
    return !!paths.filter(function (path) {
      const isWildcard = path.includes('*');
      if (isWildcard) {
        const removedWildcardPath = path.replace(/\/\*/g, '');
        return inputPaths.includes(removedWildcardPath);
      }
      return inputPaths === path;
    }).length;
  };

  // 스크롤 위치 업데이트
  const updateScroll = () => {
    const scrollY = window.pageYOffset || document.documentElement.scrollTop;

    setIsScroll(scrollY > 0);
  };

  useEffectOnce(() => {
    window.addEventListener('scroll', updateScroll);
    return () => {
      window.removeEventListener('scroll', updateScroll);
    };
  });

  return (
    <header
      className={cns(S.header, {
        [S.header_movement]:
          isScroll && !isNotAllowPaths(router.pathname, notAllowPaths),
      })}>
      <div id="header_with_margin" className={S.contents}>
        <div
          className={cns(S.nav_wrapper, {
            [S.nav_active]: isShowMobileNav,
          })}>
          {<Logos />}
          <Unless condition={isHideMenu}>
            <SSRSafeSuspense>
              <If condition={isPortableDevice}>
                <Then>
                  {/* mobile navigation */}
                  <MobileGlobalNavigationBarMenu
                    headerContent={filteredHeaderContent}
                    isShow={isShowMobileNav}
                    setIsShow={() => setIsShowMobileNav(!isShowMobileNav)}
                  />
                </Then>
                <Else>
                  {/* desktop navigation */}
                  <GlobalNavigationBarMenu
                    headerContent={filteredHeaderContent}
                  />
                </Else>
              </If>
            </SSRSafeSuspense>
          </Unless>
        </div>
        <div className={S.flex_display}>
          <LanguageFilterMenu />
          <SSRSafeSuspense>
            <HeaderCTAButton isHeaderCTA />
          </SSRSafeSuspense>
          <Unless condition={isHideMenu}>
            <button
              className={S.nav_mobile_button}
              onClick={() => setIsShowMobileNav(!isShowMobileNav)}>
              <MenuIcon width={28} height={28} />
            </button>
          </Unless>
        </div>
      </div>
    </header>
  );
};

/**
 * @returns 언어 변경 버튼 컴포넌트
 */
const LanguageFilterMenu = () => {
  const router = useRouter();
  const { user } = useAuth();
  const setLocale = (locale?: Language) => {
    if (locale) {
      setCookie('preferredLocale', locale);
    } else {
      deleteCookie('preferredLocale');
    }
    router.replace(router.asPath, router.asPath, { locale: locale ?? false });
  };

  return (
    <>
      {(isDevelopment || user?.snsId.includes('@zep.us')) && (
        <Menu as="div" className={S.header_menu}>
          <Menu.Button
            id="developerLanguageSet"
            className={S.language_filter_button}>
            언어변경
          </Menu.Button>
          <Menu.Items className={cns(S.menu_items, S.language_items)}>
            <Menu.Item as="a" onClick={() => setLocale()}>
              브라우저 설정
            </Menu.Item>
            <Menu.Item as="a" onClick={() => setLocale('ko')}>
              한국어
            </Menu.Item>
            <Menu.Item as="a" onClick={() => setLocale('en')}>
              영어
            </Menu.Item>
            <Menu.Item as="a" onClick={() => setLocale('ja')}>
              일본어
            </Menu.Item>
          </Menu.Items>
        </Menu>
      )}
    </>
  );
};
